.auth-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 62px);
  margin-top: -69px;
  background: url(../../../images/awe/auth/auth-background.png) bottom / cover;

  .auth-btn {
    background: linear-gradient(93.52deg, #EE713C 31.22%, #F9C93B 120.11%);
    border-radius: 8px;
    height: 50px;
    
    span {
      color: #fff !important;
      font-weight: 500;
    }
  
    &:hover {
      background: linear-gradient(93.52deg, #EE713C 100%, #F9C93B 120.11%);
      span {
        color: #fff !important;
      }
    }
  }

  .adworld-logo {
    padding-bottom: 20px;
    text-align: center;
  }

  &-form-container {
    z-index: 1;
    width: 550px;
    max-width: 95%;
    padding: 70px;
    margin: 25px 15px 50px 15px;
    border: none;
    position: relative;

    .auth-popup-bg {
      position: absolute;
      z-index: -1;
      height: calc(100% + 6px);
      width: calc(100% + 6px);
      top: -3px;
      bottom: 0;
      right: 0;
      left: -3px;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 30px;
      z-index: 2;
      gap: 20px;

      img {
        height: 60px;
        width: auto;
      }

      .title {
        font-size: 23px;
        color: rgb(24, 26, 85);
        text-transform: uppercase;
        letter-spacing: 0.1em;

        strong {
          font-weight: 700;
        }
      }

      .place-date {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 10px;
        padding: 5px 15px;
        width: 320px;
        font-size: 19px;
        font-weight: 600;
        background: linear-gradient(90deg, #FFBC0F 0%, #FFE600 100%);
        p {
          text-transform: uppercase;
          margin: 0;
          color: #170E2F;
        }
      }
    }

    .ant-form {
      &-item {
        padding: 0 25px;
        color: #fff;

        span {
          color: #fff;
        }

        a {
          color: #fff;
          text-decoration: underline;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.password-form-item {
          margin-bottom: 7px;
        }

        &.ant-form-item-has-error {
          .ant-form-item-control-input {
            .ant-input-affix-wrapper {
              background-color: #4E5255;
              border-color: #4E5255;
              border-radius: 10px;
            }
          }
        }

        .ant-form-item-label label {
          color: #fff;
        }

        &-control-input {
          .ant-input-affix-wrapper {
            padding: 8px 12px;
            border-width: 2px !important;
            border-radius: 10px;
            box-shadow: none;
            background-color: #4E5255;
            border-color: #4E5255;

            .ant-input-prefix {
              margin-right: 10px;

              svg {
                color: rgb(172, 172, 172);
              }
            }

            input {
              font-size: 15px;
              color: #fff;
              background-color: #4E5255;
            }
          }

          &-content {
            > input {
              padding: 8px 12px;
              font-size: 15px;
              border-color: rgb(233, 233, 233);
              border-width: 2px !important;
              border-radius: 5px;
              box-shadow: none;
            }
          }
        }

        .ant-form-item-explain {
          margin-bottom: 10px;
          color: #fff;
        }
      }

      hr {
        height: 1px;
        margin: 15px 0;
        background-color: rgb(224, 224, 224) !important;
        border: none;
      }

      .forgot-password {
        padding: 0 25px;
        margin-bottom: 14px;
        font-weight: 700;
        text-align: right;

        a {
          font-size: 15px;
          color: #fff;
          background-clip: text;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .didnt-receive-login {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background-clip: text;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .dubai-2023-access-link {
    height: 40px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 5vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    text-align: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;

    p {
      margin: 0;
      font-weight: 600;
      line-height: 14px;
    }
    span {
      font-size: 24px;
    }

    &:hover {
      cursor: pointer;
      pointer-events: auto;
      opacity: 0.9;
      color: #EE713C;
    }
  }
}
